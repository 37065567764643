import * as React from 'react';
import thumbnailImage from 'assets/images/DesignServices/thumbnail.png';
import { servicePath } from 'bundles/App/routes';
import { HashLink as Link } from 'react-router-hash-link';

const Ad = () => (
  <div
    className="mt-5 flex h-60 w-full items-center rounded-md bg-cover 
    bg-no-repeat p-5 text-center font-hvBold text-xl text-white shadow-lg shadow-gray-400 -md:text-lg"
    style={{
      backgroundImage: `url('${thumbnailImage}')`,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backgroundBlendMode: 'multiply',
    }}
  >
    <div data-cy="worriedAboutDesignText">
      Worried about design issues delaying your print job? We can help fix file issues, update your designs,
      or even create something new! Get in touch with a{' '}
      <Link
        data-cy="worriedAboutDesignLink"
        className="!font-hvBold !text-xl text-white -md:!text-lg"
        to={`${servicePath.designServices}#design-services-form`}
        color="white"
        underline="always"
        smooth
      >
        designer
      </Link>{' '}
      today!
    </div>
  </div>
);

export default Ad;
